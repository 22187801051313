import React, { useEffect, useState } from 'react';
import CustomCursor from "./components/CustomCursor";
import FAQItem from "./components/FAQItem";
import Article from "./components/Article";
import useFamilyHover from './hooks/useFamilyHover';

function Home() {
	useFamilyHover();

	const initialTokens = [
		{ id: 1, src: "https://i.seadn.io/s/raw/files/2890ad083ba9e119f8907ec077f478f5.png?auto=format&dpr=1&w=512", alt: "IlluminatiNFT Token" },
		{ id: 2, src: "https://i.seadn.io/s/raw/files/ba193d37d98ecd5db89fab75b0a79628.png?auto=format&dpr=1&w=512", alt: "goblintown Token" },
		{ id: 3, src: "https://i.seadn.io/s/raw/files/c976fdeec9b9b71572ca248c353afdc2.png?auto=format&dpr=1&w=512", alt: "BiG iNC Token" },
		{ id: 4, src: "https://i.seadn.io/gcs/files/83059fef7ea1dbad9d29ca3a39a2782f.gif?auto=format&dpr=1&w=640", alt: "UNDAO Token" },
		{ id: 5, src: "https://i.seadn.io/s/raw/files/79ea1109d7543fb917867a11b0db0156.png?auto=format&dpr=1&w=640", alt: "grumpl Token" },
	];

	const [tokens, setTokens] = useState(initialTokens);
	const [countdown, setCountdown] = useState({
		days: 30,
		hours: 0,
		minutes: 0,
		seconds: 0,
	});

	useEffect(() => {
		shuffleTokens();
		const targetUnixTime = 1711609200000;
		const interval = setInterval(() => {
			const now = Date.now();
			const distance = targetUnixTime - now;

			const days = Math.floor(distance / (1000 * 60 * 60 * 24));
			const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
			const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
			const seconds = Math.floor((distance % (1000 * 60)) / 1000);

			setCountdown({ days, hours, minutes, seconds });

			if (distance < 0) {
				clearInterval(interval);
				setCountdown({ days: 0, hours: 0, minutes: 0, seconds: 0 });
			}
		}, 1000);

		return () => clearInterval(interval);
	}, []);

	const shuffleTokens = () => {
		const shuffled = [...tokens].sort(() => 0.5 - Math.random());
		setTokens(shuffled);
	};

	return (
		<div id="resurrection" className='lander'>
			<CustomCursor />
			<div id="hero" className="edge">
				{/* masthead */}
				<header id="head">
					<a href="https://trutharts.com" title="Back to the Truth Arts Homepage">
					<h1 id="truthLogo" className="target target--brand">
						<img className="logoMark" src="i/logos/truth-arts-mark.gif" alt="Truth Arts Animated Mark" title="Truth Arts Mark" />
						<span className="logoType">Truth Arts</span>
					</h1>
					</a>
					{/* main navigation */}
					<nav id="bigNav">
						<a className="navItem target target--brand" href="https://trutharts.com" title="Visit the Truth Arts Homepage">Home</a>
						<a className="navItem target target--brand" href="#knowledge" title="Learn More about Operation: Resurrection">Learn More</a>
						<a className="navItem target target--brand" href="https://blog.trutharts.com" target="_blank" rel="noreferrer" title="Visit the Truth Arts Blog">Truth Blog</a>
						<a className="button target target--brand" href="https://burn.trutharts.com" title="Enter Operation: Resurrection">Visit Dashboard</a>
					</nav>
				</header>
				{/* burn hero art */}
				<div className="art">
					<img id="heroClouds" src="i/hero/hero-clouds.png" alt="" />
					<img id="heroFlame" src="i/flame-big-grey.gif" alt="" />
					<img id="heroForeground" src="i/hero/hero-foreground.png" alt="" />
					<img id="heroDancer1" className="dancer" src="i/dancer-1.gif" alt="" />
					<img id="heroDancer2" className="dancer" src="i/dancer-2.gif" alt="" />
					<img id="heroWiggler" src="i/hero/hero-wiggler.gif" alt="" />
				</div>
				{/* burn hero copy */}
				<div className="copy">
					<h2 className="screamer">
						Burn.<br></br>
						Them.<br></br>
						All.
					</h2>
				</div>
			</div>
			<main className="site">
				{/* intro */}
				<section id="intro">
					<div className="copy">
						<h3 className="screamer">From the ashes of JPEGs, some new stuff arises</h3>
						<p>Burn Truth NFTs. Get future equity rights in Truth Arts. Rinse and repeat. Original art will be retained in the <strong>Burned Collection.</strong></p>
					</div>
					<a className="button--big target target--brand" href="https://burn.trutharts.com" title="Enter Operation: Resurrection">Visit Dashboard</a>
					<div id="burningTokens">
						{tokens.map(token => (
							<div key={token.id} className="token edge burning">
								<img src={token.src} alt={token.alt} />
							</div>
						))}
					</div>
				</section>
				{/* knowledge */}
				<section id="knowledge">
					{/* burn timer */}
					<h2 className="screamer sneaky">Learn More&hellip;</h2>
					<h3 id="timer" className="screamer wigglySides">
						<div className="pair"><strong>Burn Limit Reached!</strong> Thank you to all who participated.</div>
					</h3>

					{/* articles */}
					<Article
						title="Explainer: Everything You Need to Know"
						link="https://blog.trutharts.com/burn-your-truth-nfts-for-equity"
						date="2.27.24"
					/>
					<Article
						title="FAQs: Frequently Asked Questions"
						link="https://blog.trutharts.com/operation-resurrection-faqs"
						date="2.27.24"
					/>
					<Article
						title="Announcement: Truth Labs is Now Truth Arts Inc."
						link="https://blog.trutharts.com/introducing-truth-arts-inc"
						date="2.27.24"
					/>
					{/* faq */}
					<div id="faq">
						<FAQItem question="How does this work?">
							<p>From today, February 27th, 2024 until March 27th, 2024 at 11:59pm Pacific time / 3am Eastern time, Truth NFT holders (including Goblintown, Illuminati, Grumpl, and McGoblin Burger holders, among others) can burn their NFTs for future equity rights in Truth Arts Inc. Once you decide that you want to burn your NFT, go to the <a href="https://burn.trutharts.com" className="target target--brand" target="_blank" rel="noreferrer" title="Enter Operation: Resurrection">Burn experience</a> and follow the steps to select which NFTs you want to burn. After the Burn window concludes, participants will be subject to a KYC process conducted by Republic, where they will be approved or rejected. If you're approved, your NFT will be burned. If you're rejected, your NFT will be returned to you 30-60 days after March 27th, 2024. People who burn their NFTs will receive their art back in a soul-bound NFT using Coinbase's BASE that is non-transferable.</p>
						</FAQItem>
						<FAQItem question="Who can participate?">
							<p>Anyone who held, as of the moment of the snapshot (February 20, 2024 at 9 a.m. EST), and continues to hold an NFT from Goblintown, Illuminati NFT, Grumpls, Big Inc, Mcgoblin Burger, or many other Truth-related collections and passes (Illuminati Merch Passes, Believer, and Undao). The 187, Illuminaries, Goblinaries, Puzzlers, Harbinger, Mentors, aksessorees and non-generative 1:1s in any collection are <strong>not</strong> eligible.</p>
						</FAQItem>
						<FAQItem question="What do I get in return?">
							<p>Each holder has the opportunity to burn NFTs for a Truth Arts Inc. SAFE worth between $100 and $50,000. The sum of the value of the NFTs you want to burn will make you eligible for different levels of equity.</p>
							<p>Anyone who burns their NFTs in Operation Resurrection will receive their art back in a soul-bound NFT using Coinbase’s BASE that is non-transferable.</p>
						</FAQItem>
						<FAQItem question="How long do I have?">
							<p>Operation: Resurrection will run for 30 days from February 27, 2024 until March 27, 2024 at 11:59pm Pacific time / 3am ET. Unfortunately, if you miss the window, you will not be able to participate.</p>
						</FAQItem>
						<FAQItem question="Who is Republic?">
							<p><a href="https://republic.com/truth-arts" className="target target--brand" target="_blank" rel="noreferrer">Republic</a> is a global financial technology firm at the frontier of democratizing access to the private markets. The investment platform has made it possible to bring this offering, which is a <a href="https://republic.com/sharedrops" className="target target--brand" target="_blank" rel="noreferrer" title="Learn More about Sharedrops on Republic's Website">sharedrop</a> (essentially airdrop of shares or another type of investment contract, like a SAFE), to market.</p>
						</FAQItem>
						<FAQItem question="Can I unburn my NFT?">
							<p>You are able to cancel your participation up until the burn window closes which is March 27th, 2024 at 11:59pm Pacific time / 3am Eastern time. In order to cancel, you must send a request to help@trutharts.com with the email and wallets used. You will receive your NFTs 30-60 days after the burn window closes on March 27th, 2024.</p>
							<p>After that date, once an NFT is burned, it is not possible to reverse the action.</p>
						</FAQItem>
						<FAQItem question="Is there any reason to not burn my tokens?">
							<p>If you want to sell your NFTs in the future, you should not burn them. Additionally, if you might want to sell your NFTs in the next 30-60 days, you also should not burn them because your NFTs will be locked up for the next 30-60 days in a holding period. It's worth noting that if you burn your NFTs, you will retain the art, because you will receive the same art in a soul-bound NFT called the Burned Collection.</p>
						</FAQItem>
						<FAQItem question="What is a SAFE?">
							<p>A SAFE stands for a "Simple Agreement for Future Equity." (Source: <a href="https://republic.com/help/how-the-crowd-safe-works" className="target target--brand" target="_blank" rel="noreferrer" >Republic</a>)</p>
							<p>A SAFE is an investment agreement between investors and companies looking to raise capital. Individuals make investments in exchange for the chance to earn a return—in the form of equity in the company—if the company experiences an equity financing or liquidity event (e.g., another round of financing, conducts a token-generation event*, is acquired or has an IPO).</p>
							<p>The SAFE was originally created by Y Combinator and can be customized infinitesimally. Generally, SAFEs are widely used by angels and VCs investing in startups.</p>
							<p>More information can be found on <a href="https://republic.com/help/how-the-crowd-safe-works" className="target target--brand" target="_blank" rel="noreferrer" >Republic</a>.</p>
						</FAQItem>
						<FAQItem question="Can I Burn NFTs Held in a Vault?">
							<p>Yes. In order to burn NFTs, you must connect the wallet that is holding the tokens. You have two choices with a vault: 1. Connect the vault and burn or 2. Send your tokens to a delegated wallet and burn from that one. We look up wallets delegated on <a href="https://delegate.xyz/" className="target target--brand" target="_blank" rel="noreferrer">delegate.xyz</a> to ensure that NFTs transferred between delegated wallets are still eligible for the burns. If you have already delegated a wallet, then you are good to go. If you haven't and want to do this, please follow instructions on <a href="https://delegate.xyz/" className="target target--brand" target="_blank" rel="noreferrer">Delegate</a>.</p>
						</FAQItem>
						<FAQItem question="How much equity is set aside for the burn?">
							<p>Truth Arts has set aside $1.235M in future equity rights for the burn. This is first come, first served. Only the first KYC-approved holders who have committed to burning NFTs valued at $1.235M will be eligible to receive SAFEs. Any holder who submits an NFT after this threshold has been reached will not be eligible for a SAFE and will have their NFTs returned 30-60 days after March 27th, 2024.</p>
						</FAQItem>
						<FAQItem question="Why are those NFTs up there different sizes?">
							<p>It signifies the memory of the Five Burning Pillars of Funtime (for the season of Chaos), Yelling A Lot (for the season of Discord), Madness (for the season of Confusion), Form 123-E-5.17 (for the season of Bureaucracy), and Migraine (for the season of Aftermath), which were dedicated to our beloved Eris by the priestesses of the Temple of Snafoo, and which once stood atop Mt. Beig’n’poyntee in the Atlantean district of Supsupsup, before the forces of Gruad Stoneface destroyed them in a fiery calamity during the war of 2 YOLD. Thus do we remember. Hail Hail Hail Hail Hail!</p>
						</FAQItem>
					</div>
				</section>

				{/* action */}
				<section id="action" className="edge">
					<div className="art">
						<img id="actionDancer2" className="dancer" src="i/dancer-2.gif" alt="" />
						<img id="actionDancer1" className="dancer" src="i/dancer-1.gif" alt="" />
					</div>
					<h3 className="screamer">What the heck are you waiting for?</h3>
					<a href="https://burn.trutharts.com" className="button--big inverse target target--brand" title="Enter Operation: Resurrection" >Visit Dashboard</a>
				</section>
			</main>
			<footer id="foot">
				<div id="contact" className="wigglySides hasToggle ">
					<h3 className="screamer target target--brand"><a href="mailto:help@trutharts.com">help@trutharts.com</a></h3>
					<i className="toggle"></i>
				</div>
				<div id="footContent">
					<div id="footAbout">
						<div id="footLogo" className="target target--brand">
							<img className="logoMark" src="i/logos/truth-arts-mark.gif" alt="Truth Arts Animated Mark" title="Truth Arts Mark" />
						</div>
						<p>Truth Arts is a collective of <strong>high-powered creative mutant world-builders</strong>, here to tell stories so <strong>wild</strong>, <strong>fantastical</strong>, <strong>heart-wrenching</strong>, <strong>touching</strong>, and <strong>epically ridiculous</strong> that they can't not be believed.</p>
					</div>
					<div id="footSocial" className="edge">
						<h3>Truth Social</h3>
						<a id="xLink" href="https://twitter.com/truth" target="_blank" rel="noreferrer" className="socialLink target target--brand edge" title="Follow Truth Arts on X">
							<i className='socialIcon'></i>
							<h5>Follow on X</h5>
						</a>
						<a id="discordLink" href="https://discord.gg/illuminati" target="_blank" rel="noreferrer" className="socialLink target target--brand edge" title="Join the Truth Arts Discord">
							<i className='socialIcon'></i>
							<h5>Join the Discord</h5>
						</a>
						<a id="republicLink" href="https://republic.com/truth-arts" target="_blank" rel="noreferrer" className="socialLink target target--brand edge" title="View the Truth Arts Deal Page on Republic">
							<i className='socialIcon'></i>
							<h5>Visit Republic</h5>
						</a>
					</div>
				</div>
				<div className="wiggleDivide">
					<img src="i/wiggle-line.png" alt=""></img>
				</div>
				<div id="footFoot">
					<div id="colo">&copy;2024 Truth Arts Inc. All Rights Reserved.</div>
					<div id="legal">
						<a href="https://truth-public-content.s3.us-east-2.amazonaws.com/Privacy+Policy_2.21.24.pdf" className="target target--brand" target="_blank" rel="noreferrer" title="Read the Truth Arts Privacy Policy">Privacy Policy</a>
						<a href="https://truth-public-content.s3.us-east-2.amazonaws.com/Terms+and+Conditions.pdf" className="target target--brand" target="_blank" rel="noreferrer" title="Read the Truth Arts Terms and Conditions">Terms and Conditions</a>
					</div>
				</div>
			</footer>
		</div>
	);
}

export default Home;
