import React, { useState } from 'react';

const FAQItem = ({ question, children }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="faqItem wigglySides hasToggle" onClick={() => setIsOpen(!isOpen)}>
            <h3 className="question screamer target target--brand">{question}</h3>
            {isOpen && <div className="answer">{children}</div>}
            <i className="toggle"></i>
        </div>
    );
};

export default FAQItem;