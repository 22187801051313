import React from 'react';

const Article = ({ title, link, date }) => {

    return (
        <a href={link} className="article edge target target--brand hasToggle" target="_blank" rel="noreferrer" title={`Read "${title}" on the Truth Blog`}>
            <div className="labelGroup">
                <h5 className="label">Blog</h5>
                <h5 className="label secondary">{date}</h5>
            </div>
            <h4>{title}</h4>
            <i className="toggle"></i>
        </a >
    );
};

export default Article;