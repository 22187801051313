import { useEffect } from 'react';

const Mint = () => {
  useEffect(() => {
    window.location.href = "https://burn.trutharts.com/mint";
  }, []);

  return null;
};

export default Mint;