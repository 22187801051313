import { useState, useEffect } from 'react';

const useFamilyHover = () => {
	const [hoveredFamily, setHoveredFamily] = useState('');

	useEffect(() => {
		const handleMouseEnter = (event) => {
			const familyClass = Array.from(event.target.classList).find(className => className.startsWith('family--'));
			if (familyClass) {
				setHoveredFamily(familyClass);
				document.querySelectorAll(`.${familyClass}`).forEach(element => {
					element.classList.add('hovered');
				});
			}
		};

		const handleMouseLeave = (event) => {
			const familyClass = Array.from(event.target.classList).find(className => className.startsWith('family--'));
			if (familyClass) {
				setHoveredFamily('');
				document.querySelectorAll(`.${familyClass}`).forEach(element => {
					element.classList.remove('hovered');
				});
			}
		};

		const familyElements = document.querySelectorAll('.family');
		familyElements.forEach(element => {
			element.addEventListener('mouseenter', handleMouseEnter);
			element.addEventListener('mouseleave', handleMouseLeave);
		});

		// Cleanup function to remove event listeners
		return () => {
			familyElements.forEach(element => {
				element.removeEventListener('mouseenter', handleMouseEnter);
				element.removeEventListener('mouseleave', handleMouseLeave);
			});
		};
	}, []);

};

export default useFamilyHover;
